import { WalletContext } from "context";
import { useContext } from "react";

import { WalletStatus, WalletType, Network } from "models";

const useWallet = (): {
  account: string;
  connect: (type: WalletType) => void;
  disconnect: () => void;
  status: WalletStatus;
  connected: boolean;
  connecting: boolean;
  balance: string;
  networkId: number | null;
  walletType: WalletType;
  activeNetwork: Network;
  safleId: string;
  onNetworkChange: (network: Network) => void;
} => {
  const wallet = useContext(WalletContext);

  const connected = wallet.status === WalletStatus.connected;
  const connecting = wallet.status === WalletStatus.connecting;

  return {
    ...wallet,
    connected,
    connecting,
  };
};

export default useWallet;
