import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { errorCodes, serializeError, EthereumRpcError } from "eth-rpc-errors";

import {
  openNotification,
  openErrorNotification,
} from "components/Notification";

import ethereum from "utils/ethereum";
import { WalletType } from "models";

type CustomError = {
  originalError?: {
    reason: string;
    error?: {
      message: string;
    };
  };
};

const useRoundMutations = (
  depositTokenAddress: string,
  contractAddress: string,
  walletType: WalletType
) => {
  const queryClient = useQueryClient();
  const [transactionLink, setTransactionLink] = useState<string>("");

  const refetchQuery = useCallback(() => {
    queryClient.invalidateQueries("rounds");
  }, [queryClient]);

  const handleError = useCallback((error) => {
    const rpcError = error as EthereumRpcError<CustomError>;
    const { code, data } = serializeError(rpcError);

    if (code === errorCodes.provider.userRejectedRequest) {
      openErrorNotification("Transaction has been canceled");
    } else {
      const errorData = data as CustomError;
      const message =
        errorData?.originalError?.error?.message ||
        errorData?.originalError?.reason ||
        "Transaction error";

      openErrorNotification(`Transaction rejected: ${message}`);
    }
  }, []);

  const handleSuccess = useCallback(
    (url: string, message: string) => {
      refetchQuery();
      openNotification(message, url);
    },
    [refetchQuery]
  );

  const handleTransactionLink = useCallback(async (mutationFn) => {
    setTransactionLink("");
    const transactionResponse = await mutationFn;
    const link = ethereum.getExplorerLink(transactionResponse.hash);
    setTransactionLink(link);

    await transactionResponse.wait();
    return link;
  }, []);

  const approve = useMutation(
    async () => {
      if (walletType !== WalletType.safle) {
        return handleTransactionLink(
          ethereum.approve(depositTokenAddress, contractAddress)
        );
      }
      await ethereum.approveSafle(depositTokenAddress, contractAddress);
      return "";
    },
    {
      onSuccess: (url: string) => {
        if (url) {
          handleSuccess(url, "Approve");
        }
      },
      onError: handleError,
    }
  );

  const deposit = useMutation(
    async (value: number | string) => {
      if (walletType !== WalletType.safle) {
        return handleTransactionLink(
          ethereum.deposit(value, depositTokenAddress, contractAddress)
        );
      }
      await ethereum.depositSafle(value, depositTokenAddress, contractAddress);
      return "";
    },
    {
      onSuccess: (url: string) => {
        if (url) {
          handleSuccess(url, "Deposit");
        }
      },
      onError: handleError,
    }
  );

  const withdraw = useMutation(
    async () => {
      if (walletType !== WalletType.safle) {
        return handleTransactionLink(ethereum.withdraw(contractAddress));
      }
      await ethereum.withdrawSafle(contractAddress);
      return "";
    },
    {
      onSuccess: (url: string) => {
        if (url) {
          handleSuccess(url, "Withdraw");
        }
      },
      onError: handleError,
    }
  );

  const claim = useMutation(
    async () => {
      if (walletType !== WalletType.safle) {
        return handleTransactionLink(ethereum.claim(contractAddress));
      }
      await ethereum.claimSafle(contractAddress);
      return "";
    },
    {
      onSuccess: (url: string) => {
        if (url) {
          handleSuccess(url, "Claim");
        }
      },
      onError: handleError,
    }
  );

  const withdrawAndStake = useMutation(
    async () => {
      if (walletType !== WalletType.safle) {
        return handleTransactionLink(
          ethereum.withdrawAndStake(contractAddress)
        );
      }
      await ethereum.withdrawAndStakeSafle(contractAddress);
      return "";
    },
    {
      onSuccess: (url: string) => {
        if (url) {
          handleSuccess(url, "Withdraw and Stake");
        }
      },
      onError: handleError,
    }
  );

  return {
    isLoading:
      approve.isLoading ||
      deposit.isLoading ||
      withdraw.isLoading ||
      claim.isLoading ||
      withdrawAndStake.isLoading,
    approve: approve.mutateAsync,
    deposit: deposit.mutateAsync,
    withdraw: withdraw.mutateAsync,
    claim: claim.mutateAsync,
    withdrawAndStake: withdrawAndStake.mutateAsync,
    transactionLink,
  };
};

export default useRoundMutations;
