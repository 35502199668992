import clsx from "clsx";

import { Avatar } from "antd";
import { AvatarSize } from "antd/lib/avatar/SizeContext";

import styles from "./RoundAvatar.module.css";

type RoundAvatarProps = {
  depositIcon: string;
  rewardIcon: string;
  size?: AvatarSize;
};

const RoundAvatar = ({
  depositIcon,
  rewardIcon,
  size = 52,
}: RoundAvatarProps) => (
  <>
    <Avatar
      size={size}
      className={styles.avatar}
      src={depositIcon}
      alt="deposit coin"
    />
    <Avatar
      size={size}
      className={clsx(styles.avatar, styles.avatarToken)}
      src={rewardIcon}
      style={{ backgroundColor: "#787882" }}
      alt="reward coin"
    />
  </>
);

export default RoundAvatar;
