import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(relativeTime);

const DEFAULT_FORMAT = "DD MMM YYYY HH:mm";

export const formatUTC = (
  timestamp: number | string,
  format: string = DEFAULT_FORMAT
): string => `${dayjs.utc(timestamp).format(format)} UTC`;

export const formatToDate = (timestamp: number): string => {
  const x = dayjs(timestamp);
  const now = dayjs();
  return x.from(now);
};
