export const getShortWalletAddress = (address: string) =>
  `${address.slice(0, 6)}...${address.slice(-4)}`;

export const convertToPrice = (
  amount: number | string | null,
  tokenPrice: number
): number => {
  if (!amount) {
    return 0;
  }

  return Number(amount) * tokenPrice;
};

export const abbreviateNumber = (value: number, digits = 2): string => {
  let expK = Math.floor(Math.log10(Math.abs(value)) / 3);
  let scaled = value / 1000 ** expK;

  if (Math.abs(+scaled.toFixed(digits)) >= 1000) {
    scaled /= 1000;
    expK += 1;
  }

  const SI_SYMBOLS = "apμm kMGTPE";
  const BASE0_OFFSET = SI_SYMBOLS.indexOf(" ");

  if (expK + BASE0_OFFSET >= SI_SYMBOLS.length) {
    expK = SI_SYMBOLS.length - 1 - BASE0_OFFSET;
    scaled = value / 1000 ** expK;
  } else if (expK + BASE0_OFFSET < 0) {
    return "0";
  }

  return (
    scaled.toFixed(digits).replace(/(\.|(\..*?))0+$/, "$2") +
    SI_SYMBOLS[expK + BASE0_OFFSET].trim()
  );
};
