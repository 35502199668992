export enum WalletStatus {
  connecting = "connecting",
  connected = "connected",
  disconnected = "disconnected",
}

export enum WalletType {
  metamask = "metamask",
  walletConnect = "walletConnect",
  safle = "safle",
  none = "",
}

export enum RoundState {
  New = 0,
  Active = 1,
  Successful = 2,
  Failed = 3,
}
