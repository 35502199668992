import queryString, { StringifiableRecord } from "query-string";

export default class PriceService {
  private static instance: PriceService;

  private baseUrl: string;

  private constructor(config: Record<string, string>) {
    this.baseUrl = config.baseUrl;
  }

  public static getInstance(config: Record<string, string>): PriceService {
    if (!this.instance) {
      this.instance = new PriceService(config);
    }
    return this.instance;
  }

  private get = async (endpoint: string, query: StringifiableRecord) => {
    const sanitizedBaseUrl = this.baseUrl.endsWith("/")
      ? this.baseUrl.slice(0, -1)
      : this.baseUrl;
    const sanitizedEnpoint = endpoint.startsWith("/")
      ? endpoint.slice(1)
      : endpoint;
    const url = `${sanitizedBaseUrl}/${sanitizedEnpoint}`;

    const response = await fetch(queryString.stringifyUrl({ url, query }));
    const data = await response.json();
    return data;
  };

  public fetchTokenPrice = async (
    contractAddress: string,
    currency: string
  ): Promise<number> => {
    try {
      if (!contractAddress) {
        return 0;
      }

      const data = await this.get("/simple/token_price/binance-smart-chain", {
        contract_addresses: contractAddress,
        vs_currencies: currency,
      });
      return Number(data?.[contractAddress]?.[currency] || 0);
    } catch (e) {
      console.log("TOKEN_PRICE_ERROR", e);
      return 0;
    }
  };
}
