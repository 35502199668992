import { notification } from "antd";

import { ReactComponent as SuccessLogo } from "assets/success-logo.svg";

import styles from "./Notification.module.css";

export const openNotification = (message: string, url: string) => {
  notification.open({
    message,
    description: (
      <a href={url} target="_blank" rel="noreferrer">
        View transaction
      </a>
    ),
    icon: <SuccessLogo />,
    className: styles.notification,
  });
};

export const openErrorNotification = (message: string) => {
  notification.open({
    message: "Error",
    description: message,
    className: styles.notification,
    duration: 0,
  });
};

export const openNoticeNotification = (message: string) => {
  notification.open({
    message: "Notice",
    description: message,
    className: styles.notification,
    duration: 0,
  });
};
