import { FC, useEffect, useState } from "react";

import { useWallet } from "hooks";

import { RoundConfig } from "models";
import { Loader } from "components";
import { ROUNDS } from "config";
import { useRounds } from "hooks/query";
import { RoundList } from "./components";

import styles from "./Investing.module.scss";

const Investing: FC = () => {
  const { networkId, activeNetwork } = useWallet();
  const isAvailableNetwork = networkId === activeNetwork.networkId;

  const [configs, setConfigs] = useState<RoundConfig[]>([]);

  useEffect(() => {
    if (networkId === activeNetwork.networkId) {
      const roundsByNetworkId = ROUNDS.filter(
        (roundConfig) => roundConfig.networkId === activeNetwork.networkId
      );
      setConfigs(roundsByNetworkId);
    } else {
      setConfigs([]);
    }
  }, [networkId, activeNetwork]);

  const { isLoading, rounds } = useRounds(configs);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.title}>Investment Round</h1>
      </div>
      {isAvailableNetwork && <RoundList rounds={rounds} />}
    </>
  );
};

export default Investing;
