import { Row, Col } from "antd";

import { Loader, Button } from "components";

import { ReactComponent as TransactionSuccess } from "assets/transaction-success.svg";

import styles from "./LoadingModal.module.scss";

type LoadingModalProps = {
  onClose: () => void;
  transactionLink?: string;
};

const LoadingModal = ({ onClose, transactionLink }: LoadingModalProps) => (
  <div className={styles.modal}>
    {transactionLink ? (
      <>
        <h4 className={styles.title}>Transaction successful</h4>

        <div className={styles.successWrapper}>
          <TransactionSuccess />
        </div>
      </>
    ) : (
      <>
        <h4 className={styles.title}>Sign transaction</h4>

        <Loader />

        <p className={styles.description}>
          Please, sign transaction in your wallet
        </p>
      </>
    )}

    <Row gutter={[0, 10]}>
      {transactionLink && (
        <Col span={24}>
          <Button
            className={styles.button}
            href={transactionLink}
            target="_blank"
          >
            View transaction
          </Button>
        </Col>
      )}

      <Col span={24}>
        <Button onClick={onClose} className={styles.button}>
          Close
        </Button>
      </Col>
    </Row>
  </div>
);

export default LoadingModal;
