export const ERC20TokenABI = [
  "function symbol() public view returns (string)",
  "function decimals() public view returns (uint8)",
  "function balanceOf(address _owner) public view returns (uint256 balance)",
  "function allowance(address _owner, address _spender) public view returns (uint256 remaining)",
  "function approve(address _spender, uint256 _value) public returns (bool success)",
];

export const PureFiRoundABI = [
  "function availableTokens(address _user) public view returns (uint256, uint256)",
  "function userData(address _user) public view returns (uint256, uint256, uint256, uint256, uint256, uint256)",
  "function getDateRestrictions() public view returns (uint64, uint64, uint64)",
  "function getStatus() external view returns(uint8)",
  "function depositUFI(uint256 _amountUFI) external",
  "function withdrawUFI() external",
  "function claimX() external ",
  "function withdrawUFIAndStake() public ",
  "function totalAmountX() public view returns(uint256)",
  "function priceUSDperX() public view returns(uint256)",
  "function priceUSDperUFI() public view returns(uint256)",
  "function tokenUFI() public view returns(address)",
  "function tokenX() public view returns(address)",
  "function currentBoosterValue() external view returns (uint32)",
  "function totalShares() external view returns (uint256)",
  "function exactRoundSizeInUFI() external view returns (uint256)",
  "function amountOversubscribed() external view returns (uint256)",
];
