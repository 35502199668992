import { ethers, BigNumber, PopulatedTransaction } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import { createExplorerLink } from "@metamask/etherscan-link";
import { formatFixed, parseFixed } from "@ethersproject/bignumber";
import { TransactionResponse } from "@ethersproject/abstract-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { priceService } from "api";

import { Round, Network, RoundConfig, RoundState } from "models";
import {
  DEFAULT_NETWORK,
  BSC_MAINNET_NETWORK,
  BSC_TEST_NETWORK,
  POLYGON_TESTNET_NETWORK,
  POLYGON_MAINNET_NETWORK,
  NETWORKS,
} from "config";

import { ERC20TokenABI, PureFiRoundABI } from "./abi";

const UINT256_MAX_INT = BigNumber.from(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);

class Ethereum {
  private metaMaskWallet = (window as any).ethereum;

  private widget: any;

  private provider =
    this.metaMaskWallet &&
    new ethers.providers.Web3Provider(this.metaMaskWallet);

  private account: string = "";

  private network: Network = DEFAULT_NETWORK;

  init = (
    walletProvider: null | WalletConnectProvider,
    accountsChanged: (account: string) => void,
    networkChanged: (chainId: number) => void
  ): void => {
    const newProvider = walletProvider || this.metaMaskWallet;

    this.provider = new ethers.providers.Web3Provider(newProvider);

    newProvider.on("accountsChanged", ([account]: string[]) => {
      this.account = account || "";
      accountsChanged(this.account);
    });

    const provider = new ethers.providers.Web3Provider(newProvider, "any");
    provider.on("network", ({ chainId }) => {
      // Set current network SC address
      this.network = NETWORKS[chainId];

      // Update provider when network changed
      this.provider = new ethers.providers.Web3Provider(newProvider);

      networkChanged(chainId);
    });
  };

  setWidget = (value: any) => {
    this.widget = value;
  };

  initSafle = (
    safleProvider: any,
    networkChanged: (chainId: number) => void
  ): void => {
    const newProvider = safleProvider;

    this.provider = new ethers.providers.Web3Provider(safleProvider);

    const provider = new ethers.providers.Web3Provider(newProvider, "any");
    provider.on("network", ({ chainId }) => {
      this.network = NETWORKS[chainId];
      this.provider = new ethers.providers.Web3Provider(newProvider);

      networkChanged(chainId);
    });
  };

  getMetaMaskWalletAccount = async (request: boolean): Promise<string> => {
    const method = request ? "eth_requestAccounts" : "eth_accounts";
    const [account] = await this.metaMaskWallet.request({
      method,
    });
    return account || "";
  };

  isMetaMaskProviderExist = async (): Promise<boolean> => {
    const provider = await detectEthereumProvider();
    return !!provider;
  };

  setWalletAccount = (account: string): void => {
    this.account = account;
  };

  setNetwork = (newNetwork: Network): void => {
    this.network = newNetwork;
  };

  getBalance = async (): Promise<string> => {
    const balance = await this.provider.getBalance(this.account);
    return ethers.utils.formatEther(balance);
  };

  getNetwork = async (): Promise<number> => {
    const { chainId } = await this.provider.getNetwork();
    return chainId;
  };

  parseDecimals = async (
    value: number | string,
    address: string
  ): Promise<BigNumber> => {
    const contract = new ethers.Contract(address, ERC20TokenABI, this.provider);
    const decimals = await this.getDecimals(contract);
    return parseFixed(value.toString(), decimals);
  };

  getTokenAllowance = async (
    tokenContract: ethers.Contract,
    roundContractAddress: string
  ): Promise<boolean> => {
    const allowance: BigNumber = await tokenContract.allowance(
      this.account,
      roundContractAddress
    );
    return !allowance.isZero();
  };

  getRestrinctions = async (
    roundContract: ethers.Contract
  ): Promise<number[]> => {
    const restrictions: BigNumber[] = await roundContract.getDateRestrictions();
    const withdrawTime = restrictions[0].toNumber() * 1000;
    const claimTime = restrictions[1].toNumber() * 1000;
    const vestingTime = restrictions[2].toNumber() * 1000;
    return [withdrawTime, claimTime, vestingTime];
  };

  getTokenPrice = async (bscTokenAddress: string): Promise<number> => {
    const price = await priceService.fetchTokenPrice(bscTokenAddress, "usd");
    return price;
  };

  getDecimals = async (contract: ethers.Contract): Promise<number> => {
    try {
      const decimals = await contract.decimals();
      return decimals;
    } catch {
      return 18;
    }
  };

  getRound = async (config: RoundConfig): Promise<Round | null> => {
    try {
      const { contractAddress, startDate, bscDepositTokenAddress } = config;
      const signer = this.provider.getSigner(this.account);
      const roundContract = new ethers.Contract(
        contractAddress,
        PureFiRoundABI,
        signer
      );

      const depositTokenAddress = await roundContract.tokenUFI.call();
      const rewardTokenAddress = await roundContract.tokenX.call();

      const depositTokenContract = new ethers.Contract(
        depositTokenAddress,
        ERC20TokenABI,
        signer
      );

      const rewardTokenContract = new ethers.Contract(
        rewardTokenAddress,
        ERC20TokenABI,
        signer
      );

      // get deposit token price
      const depositTokenPrice = await this.getTokenPrice(
        bscDepositTokenAddress
      );

      // get deposit token decimals
      const depositTokenDecimals = await this.getDecimals(depositTokenContract);

      // get reward token decimals
      const rewardTokenDecimals = await this.getDecimals(rewardTokenContract);

      const state = (await roundContract.getStatus()) as RoundState;

      // get user balance
      const balanceOfAccount: BigNumber = await depositTokenContract.balanceOf(
        this.account
      );
      const balance = formatFixed(balanceOfAccount, depositTokenDecimals);

      // get deposit token allowance
      const allowance = await this.getTokenAllowance(
        depositTokenContract,
        roundContract.address
      );

      // get withdraw time
      const [withdrawTime, claimTime, vestingTime] =
        await this.getRestrinctions(roundContract);

      const userData = await roundContract.userData(this.account);

      // calc user deposit
      const deposit = +formatFixed(userData[0], depositTokenDecimals);

      const totalAmountX = await roundContract.totalAmountX.call();
      const priceUSDperX = await roundContract.priceUSDperX.call();

      const formattedTotalAmountX = Number(
        formatFixed(totalAmountX, rewardTokenDecimals)
      );
      // decimals for price is 9 always
      const formattedPriceUSDperX = Number(formatFixed(priceUSDperX, 9));

      // get total
      const balanceOfRound: BigNumber = await depositTokenContract.balanceOf(
        roundContract.address
      );
      let total =
        Number(formatFixed(balanceOfRound, depositTokenDecimals)) *
        depositTokenPrice;

      if (state === RoundState.Successful) {
        const exactRoundSizeInUFI =
          await roundContract.exactRoundSizeInUFI.call();
        const amountOversubscribed =
          await roundContract.amountOversubscribed.call();
        total =
          Number(
            formatFixed(
              exactRoundSizeInUFI.add(amountOversubscribed),
              depositTokenDecimals
            )
          ) * depositTokenPrice;
      }

      const target = formattedTotalAmountX * formattedPriceUSDperX;

      // set reward token price
      const rewardTokenPrice = formattedPriceUSDperX;

      // calc user reward
      const totalShares = await roundContract.totalShares();

      // userData[3] - userShares
      const reward = totalShares.isZero()
        ? 0
        : (Number(formatFixed(totalAmountX, rewardTokenDecimals)) *
            Number(formatFixed(userData[3], depositTokenDecimals))) /
          Number(formatFixed(totalShares, depositTokenDecimals));

      // allowed to withdraw
      const allowedToWithdraw = +formatFixed(userData[4], depositTokenDecimals);

      // allowed to claim
      const allowedToClaim = +formatFixed(userData[5], rewardTokenDecimals);

      // withdrawn
      const withdrawn = +formatFixed(userData[1], depositTokenDecimals);

      // calc booster
      const boosterValue = await roundContract.currentBoosterValue();
      const booster = boosterValue !== 0 ? (boosterValue / 100).toFixed(1) : "";

      const isReady = Date.now() > +new Date(startDate);

      const round: Round = {
        ...config,
        total,
        target,
        deposit,
        reward,
        state,
        allowance,
        balance,
        withdrawTime,
        claimTime,
        vestingTime,
        depositTokenAddress,
        rewardTokenAddress,
        depositTokenPrice,
        rewardTokenPrice,
        booster,
        allowedToWithdraw,
        allowedToClaim,
        withdrawn,
        isReady,
      };
      return round;
    } catch (error) {
      console.log("ERROR", error);
      return Promise.resolve(null);
    }
  };

  approve = async (
    liquidityTokenAddress: string,
    contractAddress: string
  ): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      liquidityTokenAddress,
      ERC20TokenABI,
      signer
    );

    const approved: TransactionResponse = await contract.approve(
      contractAddress,
      UINT256_MAX_INT
    );
    return approved;
  };

  deposit = async (
    value: number | string,
    depositTokenAddress: string,
    contractAddress: string
  ): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      PureFiRoundABI,
      signer
    );

    const amount = await this.parseDecimals(value, depositTokenAddress);
    const createdDeposit: TransactionResponse = await contract.depositUFI(
      amount
    );
    return createdDeposit;
  };

  withdraw = async (contractAddress: string): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      PureFiRoundABI,
      signer
    );

    const createdWithdraw: TransactionResponse = await contract.withdrawUFI();
    return createdWithdraw;
  };

  claim = async (contractAddress: string): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      PureFiRoundABI,
      signer
    );

    const createdClaim: TransactionResponse = await contract.claimX();
    return createdClaim;
  };

  withdrawAndStake = async (
    contractAddress: string
  ): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      PureFiRoundABI,
      signer
    );

    const createdExit = await contract.withdrawUFIAndStake();
    return createdExit;
  };

  private estimateGasPriceAndLimit = async (
    signer: any,
    contract: ethers.Contract,
    methodName: string,
    ...params: Array<any>
  ): Promise<{ gasPrice: number; gasLimit: number }> => {
    const gasPriceBN = await signer.getGasPrice();
    const gasLimitBN = await contract.estimateGas[methodName](...params);

    const gasPrice = Number(ethers.utils.formatUnits(gasPriceBN, "wei"));
    const gasLimit = Number(ethers.utils.formatUnits(gasLimitBN, "wei"));

    return { gasPrice, gasLimit };
  };

  private getTransactionDetails = async (
    contract: ethers.Contract,
    methodName: string,
    ...params: Array<any>
  ): Promise<{ to: string; data: string }> => {
    const populatedTransaction: PopulatedTransaction =
      await contract.populateTransaction[methodName](...params);
    return {
      data: populatedTransaction?.data || "",
      to: populatedTransaction?.to || contract.address,
    };
  };

  private signAndSendTransactionBySafle = (
    to: string,
    data: string,
    gasLimit: number,
    gasPrice: number,
    value = 0
  ): void => {
    const rawTx = {
      to,
      data,
      gasLimit,
      gasPrice,
      value,
    };

    this.widget.initSendTransaction(rawTx);
  };

  approveSafle = async (
    depositTokenAddress: string,
    contractAddress: string
  ) => {
    const signer = this.provider.getSigner(this.account);
    const depostTokenContract = new ethers.Contract(
      depositTokenAddress,
      ERC20TokenABI,
      signer
    );

    const { data, to } = await this.getTransactionDetails(
      depostTokenContract,
      "approve",
      contractAddress,
      UINT256_MAX_INT
    );

    const { gasPrice, gasLimit } = await this.estimateGasPriceAndLimit(
      signer,
      depostTokenContract,
      "approve",
      contractAddress,
      UINT256_MAX_INT
    );

    this.signAndSendTransactionBySafle(to, data, gasLimit, gasPrice);
  };

  depositSafle = async (
    value: number | string,
    depositTokenAddress: string,
    contractAddress: string
  ) => {
    const signer = this.provider.getSigner(this.account);
    const roundContract = new ethers.Contract(
      contractAddress,
      PureFiRoundABI,
      signer
    );

    const amount = await this.parseDecimals(value, depositTokenAddress);

    const { data, to } = await this.getTransactionDetails(
      roundContract,
      "depositUFI",
      amount
    );

    const { gasPrice, gasLimit } = await this.estimateGasPriceAndLimit(
      signer,
      roundContract,
      "depositUFI",
      amount
    );

    this.signAndSendTransactionBySafle(to, data, gasLimit, gasPrice);
  };

  withdrawSafle = async (contractAddress: string) => {
    const signer = this.provider.getSigner(this.account);
    const roundContract = new ethers.Contract(
      contractAddress,
      PureFiRoundABI,
      signer
    );

    const { data, to } = await this.getTransactionDetails(
      roundContract,
      "withdrawUFI"
    );

    const { gasPrice, gasLimit } = await this.estimateGasPriceAndLimit(
      signer,
      roundContract,
      "withdrawUFI"
    );

    this.signAndSendTransactionBySafle(to, data, gasLimit, gasPrice);
  };

  claimSafle = async (contractAddress: string) => {
    const signer = this.provider.getSigner(this.account);
    const roundContract = new ethers.Contract(
      contractAddress,
      PureFiRoundABI,
      signer
    );

    const { data, to } = await this.getTransactionDetails(
      roundContract,
      "claimX"
    );

    const { gasPrice, gasLimit } = await this.estimateGasPriceAndLimit(
      signer,
      roundContract,
      "claimX"
    );

    this.signAndSendTransactionBySafle(to, data, gasLimit, gasPrice);
  };

  withdrawAndStakeSafle = async (contractAddress: string) => {
    const signer = this.provider.getSigner(this.account);
    const roundContract = new ethers.Contract(
      contractAddress,
      PureFiRoundABI,
      signer
    );

    const { data, to } = await this.getTransactionDetails(
      roundContract,
      "withdrawUFIAndStake"
    );

    const { gasPrice, gasLimit } = await this.estimateGasPriceAndLimit(
      signer,
      roundContract,
      "withdrawUFIAndStake"
    );

    this.signAndSendTransactionBySafle(to, data, gasLimit, gasPrice);
  };

  getExplorerLink = (hash: string): string => {
    const { networkId } = this.network;

    if (networkId === BSC_MAINNET_NETWORK.networkId) {
      return `https://bscscan.com/tx/${hash}`;
    }

    if (networkId === BSC_TEST_NETWORK.networkId) {
      return `https://testnet.bscscan.com/tx/${hash}`;
    }

    if (networkId === POLYGON_TESTNET_NETWORK.networkId) {
      return `https://mumbai.polygonscan.com/tx/${hash}`;
    }

    if (networkId === POLYGON_MAINNET_NETWORK.networkId) {
      return `https://polygonscan.com/tx/${hash}`;
    }

    return createExplorerLink(hash, networkId.toString());
  };
}

const ethereum = new Ethereum();

export default ethereum;
