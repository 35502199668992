import NumberFormat, { NumberFormatValues } from "react-number-format";

import { Avatar } from "antd";
import { Button } from "components";
import { Token } from "models";

import styles from "./NumericTextField.module.scss";

type NumericTextFieldProps = {
  value: number | string | null;
  onChange: (value: string | number) => void;
  maxValue: number;
  depositToken: Token;
};

const NumericTextField = ({
  value,
  onChange,
  maxValue,
  depositToken,
}: NumericTextFieldProps) => {
  const onValueChange = (values: NumberFormatValues) => {
    onChange(values.value);
  };

  const handleMaxClick = () => {
    onChange(maxValue);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tokenWrapper}>
        <p className={styles.poolToken}>Round Token</p>

        <div className={styles.avatarWrapper}>
          <Avatar
            size={36}
            className={styles.avatar}
            src={depositToken.icon}
            alt="Round Token"
          />
          <span className={styles.poolAbbr}>{depositToken.symbol}</span>
        </div>
      </div>

      <div className={styles.inputWrapper}>
        <Button onClick={handleMaxClick} className={styles.maxButton}>
          MAX
        </Button>

        <NumberFormat
          value={value}
          onValueChange={onValueChange}
          placeholder="0"
          thousandSeparator
          isNumericString
          allowNegative={false}
          allowLeadingZeros={false}
          className={styles.input}
          autoFocus
        />
      </div>
    </div>
  );
};

export default NumericTextField;
