import { useQueries } from "react-query";

import { RoundConfig, Round } from "models";
import ethereum from "utils/ethereum";

const useRounds = (configs: RoundConfig[]) => {
  const results = useQueries(
    configs.map((config) => ({
      queryKey: ["rounds", config.id],
      queryFn: () => ethereum.getRound(config),
    }))
  );

  const isLoading = results.some((item) => item.isLoading);
  const rounds = isLoading
    ? []
    : results
        .filter((result) => result?.data)
        .map((result) => result.data as Round);
  return { isLoading, rounds };
};

export default useRounds;
