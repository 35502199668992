import { Row, Col, Avatar } from "antd";

import { Network, WalletType } from "models";
import { getShortWalletAddress } from "helpers/formatting";
import { useWallet } from "hooks";
import { Button, NumericText } from "components";

import metamaskLogo from "assets/metamask.svg";
import walletConnectLogo from "assets/wallet-connect.svg";
import safleLogo from "assets/safle-logo_small.svg";

import styles from "./DisconnectWalletModal.module.scss";

type DisconnectWalletModalProps = {
  onClose: () => void;
  network: Network;
};

const DisconnectWalletModal = ({
  onClose,
  network,
}: DisconnectWalletModalProps) => {
  const {
    disconnect,
    walletType,
    balance,
    account,
    networkId,
    activeNetwork,
    safleId,
  } = useWallet();

  const handleDisconnect = async () => {
    await disconnect();
    onClose();
  };

  let avatar;

  if (walletType === WalletType.metamask) {
    avatar = metamaskLogo;
  }
  if (walletType === WalletType.walletConnect) {
    avatar = walletConnectLogo;
  }
  if (walletType === WalletType.safle) {
    avatar = safleLogo;
  }

  return (
    <>
      <h4 className={styles.title}>Account</h4>

      <Row gutter={[8, 8]} className={styles.row}>
        <Col span={8}>
          <p className={styles.rowTitle}>Balance</p>
          <p className={styles.rowText}>
            <NumericText
              value={networkId !== activeNetwork.networkId ? 0 : balance}
              precision={4}
              suffix={activeNetwork.symbol}
            />
          </p>
        </Col>

        <Col span={8}>
          <p className={styles.rowTitle}>Network</p>
          <p className={styles.rowText}>{network.name}</p>
        </Col>

        <Col span={8}>
          <p className={styles.rowTitle}>Wallet</p>
          <p className={styles.rowText}>{walletType}</p>
        </Col>
      </Row>

      {safleId && (
        <Row gutter={[8, 8]} className={styles.row}>
          <Col span={24}>
            <span>Safle ID: </span>
            <span className={styles.walletAddress}>{`@${safleId}`}</span>
          </Col>
        </Row>
      )}

      <Row gutter={[8, 8]} className={styles.row}>
        <Col span={24} className={styles.account}>
          <Avatar src={avatar} className={styles.accountLogo} />

          <span className={styles.walletAddress}>
            {getShortWalletAddress(account)}
          </span>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Button onClick={handleDisconnect}>Disconnect</Button>
        </Col>
      </Row>
    </>
  );
};

export default DisconnectWalletModal;
