import { ReactNode } from "react";

import { Layout as AntdLayout } from "antd";

import { useWallet } from "hooks";
import { Header, Loader, Alert } from "components";

import styles from "./Layout.module.scss";

const { Content } = AntdLayout;

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const { connected, connecting } = useWallet();

  const renderContent = () => {
    if (connecting) {
      return <Loader />;
    }

    if (connected) {
      return <>{children}</>;
    }

    return <Alert type="error">Please connect your wallet to proceed</Alert>;
  };

  return (
    <AntdLayout className={styles.layout}>
      <Header />
      <Content className={styles.content}>{renderContent()}</Content>
    </AntdLayout>
  );
};

export default Layout;
