import { ReactNode } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";

import styles from "./DetailsText.module.scss";

type DetailsTextProps = {
  label: string;
  children: ReactNode;
  link?: string;
};

const DetailsText = ({ children, label, link = "" }: DetailsTextProps) => (
  <div className={styles.detailsText}>
    <p className={styles.label}>
      <span>{label}</span>
      {link && (
        <span className={styles.hint}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <QuestionCircleOutlined style={{ color: "#983eff" }} />
          </a>
        </span>
      )}
    </p>

    <p className={styles.text}>{children}</p>
  </div>
);

export default DetailsText;
