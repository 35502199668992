/* eslint-disable prefer-template */
import React, { FC, useCallback } from "react";
import { Row, Col, Space } from "antd";
import { useQueryClient } from "react-query";
import { RoundCard } from "components";
import { Round } from "models";
import Countdown from "react-countdown";

import styles from "./RoundList.module.scss";

interface RoundListProps {
  rounds: Round[];
}

interface RendererProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

const renderer = (rendererProps: RendererProps) => {
  const { days, hours, minutes, seconds, completed } = rendererProps;
  if (completed) {
    return <span>Just started</span>;
  }

  return (
    <div className={styles.timer}>
      <div className={styles.number}>{days}</div>:
      <div className={styles.number}>{("0" + hours).slice(-2)}</div>:
      <div className={styles.number}>{("0" + minutes).slice(-2)}</div>:
      <div className={styles.number}>{("0" + seconds).slice(-2)}</div>
    </div>
  );
};

const RoundList: FC<RoundListProps> = (props: RoundListProps) => {
  const { rounds } = props;

  const queryClient = useQueryClient();
  const refetchQuery = useCallback(() => {
    queryClient.invalidateQueries("rounds");
  }, [queryClient]);

  return (
    <Space size={20} direction="vertical" className={styles.w100}>
      {rounds.map((round) => (
        <React.Fragment key={round.id}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <RoundCard round={round} />
            </Col>
          </Row>
          {!round.isReady && (
            <Row>
              <Col span={24}>
                <div>
                  <div className={styles.countdownContainer}>
                    <div className={styles.title}>Sales starts in</div>
                    <div>
                      <Countdown
                        date={new Date(round.startDate)}
                        renderer={renderer}
                        onComplete={refetchQuery}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </React.Fragment>
      ))}
    </Space>
  );
};

export default RoundList;
