import { Layout } from "antd";
import { NETWORKS } from "config";
import { useWallet } from "hooks";
import { Alert } from "components";

import { ReactComponent as Logo } from "assets/logo.svg";

import { NetworksDropdown, WalletAccount } from "./components";

import styles from "./Header.module.scss";

const Header = () => {
  const {
    connected,
    account,
    balance,
    connecting,
    networkId,
    walletType,
    activeNetwork,
    onNetworkChange,
  } = useWallet();

  const isAlert =
    !!networkId && !!account && networkId !== activeNetwork.networkId;

  return (
    <>
      <Layout.Header className={styles.header}>
        <div className={styles.logoWrapper}>
          <Logo />
        </div>

        <div className={styles.actionsWrapper}>
          <NetworksDropdown
            menu={Object.values(NETWORKS)}
            value={activeNetwork}
            onChange={onNetworkChange}
          />

          <div className={styles.walletWrapper}>
            <div className={styles.accountWrapper}>
              <WalletAccount
                connecting={connecting}
                connected={connected}
                walletType={walletType}
                balance={balance}
                account={account}
                networkId={networkId}
                activeNetwork={activeNetwork}
              />
            </div>
          </div>
        </div>
      </Layout.Header>

      {isAlert && (
        <Alert type="error">
          App network ({activeNetwork.networkId}) does not match to network
          selected in wallet: {networkId}. Learn how to change network in
          wallet.
        </Alert>
      )}
    </>
  );
};

export default Header;
